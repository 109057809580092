<div class="col-md-6 col-md-offset-3">
  <div *ngIf="!loggedIn">
  <h2>Login</h2>
  <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
    <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
      <mat-form-field>
        <input matInput
               type="text"
               class="form-control"
               name="username"
               [(ngModel)]="model.username"
               #username="ngModel"
               placeholder="Username"
               required/>
      </mat-form-field>
      <div *ngIf="f.submitted && !username.valid" class="help-block">Username is required</div>
    </div>
    <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
      <mat-form-field>
        <input matInput
               type="password"
               class="form-control"
               name="password"
               [(ngModel)]="model.password"
               #password="ngModel"
               placeholder="Password"
               required/>
      </mat-form-field>
      <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
    </div>
    <div class="form-group">
      <button mat-raised-button color="primary" [disabled]="loading" class="btn btn-primary">Login</button>
      <img *ngIf="loading"
           src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
      <a mat-raised-button [routerLink]="['/register']" [disabled]="loading" class="btn btn-link">Register</a>
    </div>
  </form>
  </div>
  <div *ngIf="loggedIn">
    <mat-form-field>
      <mat-label>Select Union to login</mat-label>
      <mat-select [(value)]="returnUnion">
        <mat-option *ngFor="let union of objectValues(unions)" [value]="union.key">
          {{union.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-raised-button color="primary" [disabled]="loading" class="btn btn-primary" (click)="redirectIfNeeded()">Login to union</button>
    <button mat-raised-button color="warn" [disabled]="loading" class="btn btn-primary" (click)="logout()">Logout</button>

  </div>
</div>
